import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const GuarantorDashboard = React.lazy(() => import('src/views/dashboard/GuarantorDashboard'))
const IncoDashboard = React.lazy(() => import('src/views/dashboard/IncoDashboard'))
const MasterAgentDashboard = React.lazy(() => import('src/views/dashboard/MasterAgentDashboard'))
const AgentDashboard = React.lazy(() => import('src/views/dashboard/AgentDashboard'))
const Profile = React.lazy(() => import('src/views/profile/Profile'))
const GuarantorMenu = React.lazy(() => import('src/views/menu/GuarantorMenu'))
const MasterMenu = React.lazy(() => import('src/views/menu/MasterMenu'))
const IncoMenu = React.lazy(() => import('src/views/menu/IncoMenu'))

const AgentMenu = React.lazy(() => import('src/views/menu/AgentMenu'))
const EditProfile = React.lazy(() => import('src/views/profile/EditProfile'))
const ChangePassword = React.lazy(() => import('src/views/profile/ChangePassword'))

//reports
const GWalletReport = React.lazy(() => import('src/views/reports/WalletReports'))
const IWalletReport = React.lazy(() => import('src/views/reports/IWalletReports'))
const MWalletReport = React.lazy(() => import('src/views/reports/MWalletReports'))
const AWalletReport = React.lazy(() => import('src/views/reports/AWalletReports'))
const WalletLedger = React.lazy(() => import('src/views/reports/Ledger'))
const CommissionReport = React.lazy(() => import('src/views/reports/Commission'))
const VIPReports = React.lazy(() => import('src/views/downlinereports/VIPreports/VipReportsList'))
const AgentReports = React.lazy(() =>
  import('src/views/downlinereports/agentreports/AgentListReports'),
)
const OperatorsListReports = React.lazy(() =>
  import('src/views/downlinereports/operatorreports/OperatorsListReports'),
)
const ActivityReportsOperatorList = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityReportsOperatorList'),
)
const ActivityLogTabs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogTabs'),
)
const ActivityLogs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogs'),
)
const OperatorsPerEventReport = React.lazy(() =>
  import('src/views/eventreports/guarantor/OperatorsPerEventReport'),
)
const DownlinePerEventReport = React.lazy(() =>
  import('src/views/eventreports/operator/DownlinePerEventReport'),
)
const OperatorReportTabs = React.lazy(() =>
  import('src/views/downlinereports/reports/OperatorTabs'),
)
const OperatorReportTabsAgent = React.lazy(() =>
  import('src/views/downlinereports/reports/AgentUnderOperatorTabs'),
)
const IncoReportTabs = React.lazy(() => import('src/views/downlinereports/reports/IncoTabs'))
const MaReportTabs = React.lazy(() => import('src/views/downlinereports/reports/MaTabs'))
const MyRequestDetails = React.lazy(() => import('src/views/reports/WalletReports'))
const Request = React.lazy(() => import('src/views/request/Request'))
const MyRequest = React.lazy(() => import('src/views/request/MyRequest'))
const RequestDownline = React.lazy(() => import('src/views/request/RequestDownline'))
const RequestDownlinePending = React.lazy(() => import('src/views/request/DownlinePendingRequest'))

const RequestApproval = React.lazy(() => import('src/views/request/RequestApproval'))
const GuarantorCommissionManagement = React.lazy(() =>
  import('src/views/commission/GuarantorCommissionManagement'),
)
const OperatorCommissionManagement = React.lazy(() =>
  import('src/views/commission/OperatorCommisionManagement'),
)
const AgentCommission = React.lazy(() => import('src/components/commission/AgentUnderMACommission'))
const AgentCommissionManagement = React.lazy(() =>
  import('src/views/commission/AgentCommissionManagement'),
)
//pages
const Incorporatros = React.lazy(() => import('src/views/mydownlines/Incorporators'))
const PendingUsers = React.lazy(() => import('src/views/mydownlines/PendingDownlines'))

const MyOperators = React.lazy(() => import('src/views/mydownlines/MasterAgents'))
const Players = React.lazy(() => import('src/views/mydownlines/Players'))
const Players2 = React.lazy(() => import('src/views/mydownlines/Players2'))
const Agents = React.lazy(() => import('src/views/mydownlines/Agents'))
//auth page
const Login = React.lazy(() => import('src/views/login/Login'))
const Register = React.lazy(() => import('src/views/register/RegistrationPage'))
const RegisterPlayer = React.lazy(() => import('src/views/register/RegistrationPagePlayer'))
const RegisterAgent = React.lazy(() => import('src/views/register/RegistrationPageAgent'))
const RegisterInco = React.lazy(() => import('src/views/register/RegistrationPageInco'))

const AuthLayout = React.lazy(() => import('src/components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('src/components/layout/MainLayout'))

export const GuarantorRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <GuarantorDashboard /> },
      { path: '/menu', name: 'Menu', element: <GuarantorMenu /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/incorporators', name: 'Downlines Management', element: <Incorporatros /> },
      { path: '/pending-incorporators', name: 'Downlines Management', element: <PendingUsers /> },
      { path: '/wallet-report', name: 'Transaction Reports', element: <GWalletReport /> },
      { path: '/wallet-ledger', name: 'Transaction Reports', element: <WalletLedger /> },
      {
        path: '/earnings-report',
        name: 'Transaction Reports',
        element: <CommissionReport />,
      },

      { path: '/masteragent-list', name: 'Operator List', element: <OperatorsListReports /> },
      {
        path: '/activity-reports',
        name: 'Activity User List',
        element: <ActivityReportsOperatorList />,
      },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/incorporator-reports',
        name: 'Transaction Reports',
        element: <IncoReportTabs />,
      },

      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/downline-requests', name: 'My Request Downline', element: <RequestDownline /> },
      {
        path: '/downline-pending-requests',
        name: 'My Request Downline',
        element: <RequestDownlinePending />,
      },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const IncoRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <IncoDashboard /> },
      { path: '/menu', name: 'Menu', element: <IncoMenu /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/master-agents', name: 'Downlines Management', element: <MyOperators /> },
      { path: '/pending-master-agents', name: 'Downlines Management', element: <PendingUsers /> },

      { path: '/wallet-report', name: 'Transaction Reports', element: <IWalletReport /> },
      { path: '/wallet-ledger', name: 'Transaction Reports', element: <WalletLedger /> },
      {
        path: '/earnings-report',
        name: 'Transaction Reports',
        element: <CommissionReport />,
      },

      { path: '/masteragent-list', name: 'Operator List', element: <OperatorsListReports /> },
      {
        path: '/activity-reports',
        name: 'Activity User List',
        element: <ActivityReportsOperatorList />,
      },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/ma-reports',
        name: 'Transaction Reports',
        element: <MaReportTabs />,
      },
      {
        path: '/manage-commission',
        name: 'Manage Commission',
        element: <GuarantorCommissionManagement />,
      },
      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/downline-requests', name: 'My Request Downline', element: <RequestDownline /> },
      {
        path: '/downline-pending-requests',
        name: 'My Request Downline',
        element: <RequestDownlinePending />,
      },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const OperatorsRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <MasterAgentDashboard /> },
      { path: '/menu', name: 'Menu', element: <MasterMenu /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/pending-agents', name: 'Downlines Management', element: <PendingUsers /> },
      { path: '/pending-players', name: 'Downlines Management', element: <PendingUsers /> },
      { path: '/players', name: 'Players', element: <Players /> },
      { path: '/agents', name: 'Agents', element: <Agents /> },
      { path: '/wallet-report', name: 'Transaction Reports', element: <MWalletReport /> },
      { path: '/wallet-ledger', name: 'Transaction Reports', element: <WalletLedger /> },
      {
        path: '/earnings-report',
        name: 'Transaction Reports',
        element: <CommissionReport />,
      },
      { path: '/agents-reports', name: 'Staff List', element: <AgentReports /> },
      { path: '/players-reports', name: 'VIP Reports', element: <VIPReports /> },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/downline-per-event-commission',
        name: 'Events Reports',
        element: <DownlinePerEventReport />,
      },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },
      {
        path: '/player-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabs />,
      },
      {
        path: '/agent-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabsAgent />,
      },

      {
        path: '/manage-commission',
        name: 'Manage Commission',
        element: <OperatorCommissionManagement />,
      },
      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/downline-requests', name: 'My Request Downline', element: <RequestDownline /> },
      {
        path: '/downline-pending-requests',
        name: 'My Request Downline',
        element: <RequestDownlinePending />,
      },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const AgentRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <AgentDashboard /> },
      { path: '/menu', name: 'Menu', element: <AgentMenu /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/players', name: 'Players', element: <Players2 /> },
      { path: '/pending-players', name: 'Downlines Management', element: <PendingUsers /> },
      { path: '/wallet-report', name: 'Transaction Reports', element: <AWalletReport /> },
      { path: '/wallet-ledger', name: 'Transaction Reports', element: <WalletLedger /> },
      {
        path: '/earnings-report',
        name: 'Transaction Reports',
        element: <CommissionReport />,
      },
      { path: '/players-reports', name: 'VIP Reports', element: <VIPReports /> },
      { path: '/activitylogs', name: 'Transaction Reports', element: <ActivityLogs /> },
      {
        path: '/downline-per-event-commission',
        name: 'Events Reports',
        element: <DownlinePerEventReport />,
      },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/player-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabs />,
      },

      {
        path: '/manage-commission',
        name: 'Manage Commission',
        element: <AgentCommissionManagement />,
      },
      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/downline-requests', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/downline-requests', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const defaultRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      { path: '/register-masteragent', name: 'Register', element: <Register /> },
      { path: '/register-agent', name: 'Register', element: <RegisterAgent /> },
      { path: '/register-incorporator', name: 'Register', element: <RegisterInco /> },
      { path: '/register-player', name: 'Register', element: <RegisterPlayer /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]

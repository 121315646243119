import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import './scss/style.scss'
import ToastContainerComponent from './components/toast/ToastContainerComponent'
import {
  AgentRoutes,
  GuarantorRoutes,
  OperatorsRoutes,
  IncoRoutes,
  defaultRoutes,
} from './routes/routes'
import { useAuth } from 'src/hooks/auth/useAuth'

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const selectRole = (userId) => {
  switch (userId) {
    case 10: {
      return GuarantorRoutes
    }
    case 11: {
      return IncoRoutes
    }
    case 7: {
      return OperatorsRoutes
    }
    case 8: {
      return AgentRoutes
    }
    default: {
      return defaultRoutes
    }
  }
}

const App = () => {
  const { token } = useAuth()
  const routesResultData = selectRole(token?.userType)
  const routesResult = useRoutes(routesResultData)

  return (
    <>
      <Suspense fallback={<Loading />}>{routesResult}</Suspense>
      <ToastContainerComponent />
    </>
  )
}

export default App
